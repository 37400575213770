<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">忘記密碼</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                忘記密碼
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8 bg-light">
      <div class="container">
        <div class="row flex-column justify-content-center align-items-center">
          <div class="col-xs-12 col-lg-4 mt-5" style="min-width: 400px">
            <div class="card bg-white card-hover mb-3">
              <div class="card-body text-center px-md-5 px-lg-6 my-2">
                <div class="card-icon-border-large border-light mtn-90 pt-6">
                  <i
                    class="fa fa-user text-light font-size-72"
                    aria-hidden="true"
                  ></i>
                </div>
                <blockquote class="blockquote blockquote-sm mt-2">
                  <footer
                    class="blockquote-footer text-uppercase font-size-32 mb-3"
                  >
                    忘記密碼嗎？
                  </footer>
                  <form action="#" method="POST" role="form">
                    <div class="input-group mb-5">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          ><i class="fa fa-user" aria-hidden="true"></i
                        ></span>
                      </div>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="請輸入你當時註冊的Email"
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        v-model="email"
                      />
                    </div>

                    <div class="form-group">
                      <button
                        type="button"
                        class="btn btn-danger text-uppercase w-100"
                        @click="forgot"
                      >
                        確認送出
                      </button>
                    </div>
                  </form>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'forgot',
  data () {
    return {
      email: ''
    }
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    forgot () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/forgetPassword`

      if (vm.email === '') {
        alert('信箱不可為空!')
        return false
      }

      vm.axios.post(api, {
        token: tokenGenerator(),
        Email: vm.email
      })
        .then((res) => {
          console.log(res)
          if (res.data.status !== '000') {
            alert('查無此信箱!')
          } else {
            alert('信件寄送成功，請去信箱查收')
          }
        })
    }
  }
}
</script>
